/* eslint-disable no-redeclare */
import React, { useState, useContext, useMemo } from 'react';
import { CognitoUser } from '@aws-amplify/auth';
import { BrowserStorageCache as Cache } from '@aws-amplify/cache';
import validRedirectUrls from '../sitecore';

const CACHE_REDIRECTURI_KEY = 'redirectUri';
const CACHE_REFERERURI_KEY = 'refererUri';

export interface RedirectContext {
  getRedirectUri: () => string | null;
  setRedirectUri: (redUri: string) => void;
  getRefererUri: () => string | null;
  setRefererUri: (refUri: string) => void;
}
export const RedirectContext = React.createContext<RedirectContext | null>(null);

export const getRedirectUriWithParams = (user: CognitoUser, redirectUri: string): string => {
  if (!redirectUri) {
    return '';
  }

  const idToken = user.getSignInUserSession()?.getIdToken().getJwtToken();
  const accessToken = user.getSignInUserSession()?.getAccessToken().getJwtToken();

  return `${redirectUri}${redirectUri.includes('?') ? '&' : '?'}id_token=${idToken}&access_token=${accessToken}`;
};

export const isRedirectUriValid = (redirectUri: string) => {
  const validUri = validRedirectUrls.find((url: string) => redirectUri.startsWith(url));
  return typeof validUri !== 'undefined' && validUri !== null;
};

export const sendPostAndRedirect = (user: CognitoUser, redirectUri: string) => {
  fetch(redirectUri, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      accessToken: user.getSignInUserSession()?.getAccessToken().getJwtToken(),
      idToken: user.getSignInUserSession()?.getIdToken().getJwtToken(),
    }),
    redirect: 'manual',
  })
    .then(() => {
      window.location.replace(redirectUri);
    })
    .catch(err => {
      console.error(err);
    });
};

export const removeRedirectUriFromCache = () => {
  Cache.removeItem(CACHE_REDIRECTURI_KEY);
};

export const RedirectProvider = ({ children }: { children: React.ReactNode }) => {
  const [redirectUri, setRedirectUriLocal] = useState<string | null>(null);
  const [refererUri, setRefererUriLocal] = useState<string | null>(null);

  const setRedirectUri = (redUri: string) => {
    Cache.setItem(CACHE_REDIRECTURI_KEY, redUri);
    setRedirectUriLocal(redUri);
  };

  const getRedirectUri = () => {
    if (redirectUri) {
      return redirectUri;
    }
    const redirectUriFromCache = Cache.getItem(CACHE_REDIRECTURI_KEY);
    if (redirectUriFromCache) {
      return redirectUriFromCache;
    }
    return null;
  };

  const setRefererUri = (refUri: string) => {
    Cache.setItem(CACHE_REFERERURI_KEY, refUri);
    setRefererUriLocal(refUri);
  };

  const getRefererUri = () => {
    if (refererUri) {
      return refererUri;
    }
    const refererUriFromCache = Cache.getItem(CACHE_REFERERURI_KEY);
    if (refererUriFromCache) {
      return refererUriFromCache;
    }
    return null;
  };

  const values = useMemo(
    () => ({
      getRedirectUri,
      setRedirectUri,
      getRefererUri,
      setRefererUri,
    }),
    [redirectUri, refererUri],
  );

  return <RedirectContext.Provider value={values}>{children}</RedirectContext.Provider>;
};

export const useRedirect = (): RedirectContext => {
  const context = useContext<RedirectContext | null>(RedirectContext);

  if (context === undefined) {
    throw new Error('`useRedirect` hook must be used within a `RedirectProvider` component');
  }
  return context as RedirectContext;
};
