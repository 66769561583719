export default {
  send: 'Senden',
  email: 'E-Mail',
  back: 'Zurück',
  signWithEmail: 'Registrieren Sie sich mit Ihrer E-Mail',
  tabLogin: 'Im System anmelden',
  tabSign: 'Anmelden',
  newPassword: 'Neues Passwort',

  confirm: {
    confirm: 'Bestätigen',
    code: 'Bestätigungscode',
    title: 'Bestätigen Sie die Anmeldung',
    required: 'Bestätigungscode ist ein Pflichtfeld',
  },

  newpassword: {
    title: 'Bitte geben Sie Ihr neues Passwort ein',
  },

  login: {
    password: 'Passwort',
    connect: 'Anmelden',
    connectUsing: 'Melden Sie sich mit Ihrem',
    withEmail: 'oder per E-Mail',
    passwordHelp: 'Min: 8 Zeichen',
    logout: 'Ausloggen',
    remember: 'Erinnere dich an meine Informationen',
    forgot: 'Ich habe mein Passwort vergessen',
  },

  signup: {
    with: 'Anmelden mit ',
    or: 'oder',
  },

  reset: {
    resetPassword: 'Passwort zurücksetzen',
    temporaryPassword: 'Bitte überprüfen Sie Ihre Mailbox auf ein temporäres Passwort',
    goToLogin: 'Zurück zum Login',
  },

  mailSignup: {
    signUp: 'Anmelden',
    firstName: 'Vorname',
    lastName: 'Nachname',
    choosePassword: 'Wählen sie ein Passwort',
    hide: 'Ausblenden',
    show: 'Angezeigt',
    passwordHelp: 'Min: 8 Zeichen einschließlich 1 Großbuchstaben, 1 niedrigere und 1 Nummer',
    country: 'Land',
    language: 'Sprache',
    receiveInfo: 'Ich möchte Informationen über Niko erhalten',
    textPrivacyPolicy: 'Mit dem Absenden dieses Formulars erklären Sie sich mit unserer ',
    privacyPolicy: 'Datenschutzerklärung einverstanden',
  },

  language: {
    french: 'Französisch',
    english: 'Englisch',
    swedish: 'Schwedisch',
    danish: 'Danishisch',
    slovak: 'Slowakisch',
    dutch: 'Niederländisch',
    german: 'Deutsche',
  },

  country: {
    belgium: 'Belgien',
    denmark: 'Dänemark',
    france: 'Frankreich',
    sweden: 'Schweden',
    slovakia: 'Slowakei',
    germany: 'Deutschland',
    netherland: 'Niederlande',
  },

  error: {
    apicall: 'Auf dem API-Server ist ein Fehler aufgetreten!',
    UserNotFoundException: 'Benutzer existiert nicht.',
    NotAuthorizedException: 'Falscher Benutzername oder falsches Passwort.',
    NotAuthorizedExceptionReset: 'Falscher Benutzername.',
    UsernameExistsException: 'Ein Konto mit der angegebenen E-Mail-Adresse ist bereits vorhanden.',
    InvalidParameterExceptionPassword:
      'Das Passwort entsprach nicht den Richtlinien: Das Passwort muss eine Länge von mindestens 8 haben.',
    InvalidPasswordExceptionLong: 'Passwort entsprach nicht der Richtlinie: Passwort nicht lang genug.',
    InvalidPasswordExceptionLower:
      'Das Passwort entsprach nicht der Richtlinie: Das Passwort muss Kleinbuchstaben enthalten.',
    InvalidPasswordExceptionUpper:
      'Das Passwort entsprach nicht der Richtlinie: Das Passwort muss Großbuchstaben enthalten.',
    InvalidPasswordExceptionNumeric:
      'Das Passwort entsprach nicht den Richtlinien: Das Passwort muss numerische Zeichen enthalten.',
    CodeMismatchException: 'Bitte geben Sie den ungültigen Bestätigungscode erneut an.',
    email: 'Vermisst',
    confirmationCode: 'Vermisst',
    AuthError: 'Falscher Bestätigungscode',
    crmError:
      // eslint-disable-next-line max-len
      'Bei der Protokollierung ist ein unerwarteter Fehler aufgetreten, bitte kontaktieren Sie Niko, um das Problem zu beheben.',
  },
};
