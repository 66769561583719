import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import YupPassword from 'yup-password';

import { Container, FormControl, TextField, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useLocale } from '../../i18n/LocaleContext';
import { AuthError, useUser } from '../../hooks/UserContext';
import LoaderButton from '../utils/LoaderButton';
import RequirementsTooltip from '../utils/PasswordRequirementsToolTip';

interface ResetValues {
  password: string;
  confirmationCode: string;
  defaultErrorMessage?: string;
}

interface ResetPasswordFormProps {
  email: string;
}

YupPassword(Yup);

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().min(8).max(256).minLowercase(1).minUppercase(1).minNumbers(1).required('required'),
  confirmationCode: Yup.string().required('required'),
});

const useStyles = makeStyles(() => ({
  form: {
    position: 'relative',
    left: -7.5,
  },

  fieldOutlinedInput: {
    '&$fieldFocused $fieldNotchedOutline': {
      borderWidth: 0,
    },
    borderRadius: 46,
    background: '#F2F2F2',
  },
  fieldFocused: {},
  fieldNotchedOutline: {
    borderWidth: 0,
  },
  errorText: {
    marginLeft: 0,
    marginBottom: 0,
  },
  mainText: {
    marginTop: 10,
    paddingLeft: 15,
  },
  redStar: {
    color: 'red',
  },
}));

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ email }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const { langAndCountryFormated } = useLocale();
  const { forgotPasswordSubmit, login, setTempUser } = useUser();

  const handleSubmit = async (
    values: ResetValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<ResetValues>,
  ) => {
    const processedEmail = email.includes('@') ? email.trim().toLowerCase() : email;
    try {
      await forgotPasswordSubmit(processedEmail, values.confirmationCode, values.password);
      await login(processedEmail, values.password);
      setTempUser(null);
      setStatus(true);
      setSubmitting(false);
      history.push(`/${langAndCountryFormated}/login`);
    } catch (e) {
      const err = e as AuthError;
      if (err.name === 'NotAuthorizedException' || err.name === 'CodeMismatchException') {
        setErrors({
          confirmationCode: err.name === 'NotAuthorizedException' ? 'NotAuthorizedExceptionReset' : err.name,
          defaultErrorMessage: err.message,
        });
      } else {
        setErrors({ password: err.name, defaultErrorMessage: err.message });
      }
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={
        {
          password: '',
          confirmationCode: '',
        } as ResetValues
      }
      validationSchema={ResetPasswordSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, status }) => (
        <Form className={classes.form}>
          <div className={classes.mainText}>
            <Typography variant="h6">
              {formatMessage({ id: 'reset.confirmResetPasswordText1' })}
              <b> {email}</b>
              <br />
              {formatMessage({ id: 'reset.confirmResetPasswordText2' })}
              <br />
              {formatMessage({ id: 'reset.confirmResetPasswordText3' })}
            </Typography>
          </div>
          <FormControl
            style={{
              marginTop: 32,
              borderLeft:
                errors.confirmationCode && touched.confirmationCode ? '3px solid #EA1E27' : '3px solid transparent',
              paddingLeft: 15,
            }}
            fullWidth
          >
            <Typography variant="h3">{formatMessage({ id: 'confirm.code' })}</Typography>
            <TextField
              id="confirmationCode"
              type="text"
              name="confirmationCode"
              margin="normal"
              variant="outlined"
              value={values.confirmationCode}
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                classes: {
                  root: classes.fieldOutlinedInput,
                  focused: classes.fieldFocused,
                  notchedOutline: classes.fieldNotchedOutline,
                },
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.errorText,
                },
              }}
              error={Boolean(errors.confirmationCode && touched.confirmationCode)}
            />
          </FormControl>
          <FormControl
            style={{
              marginTop: 32,
              borderLeft: errors.password && touched.password ? '3px solid #EA1E27' : '3px solid transparent',
              paddingLeft: 15,
            }}
            fullWidth
          >
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item xs={6} md={8}>
                <Typography variant="h3" style={{ marginBottom: 12 }}>
                  {formatMessage({ id: 'newPassword' })} <span className={classes.redStar}>*</span>
                </Typography>
              </Grid>
              <Grid item xs={1} md={1}>
                <RequirementsTooltip />
              </Grid>
            </Grid>
            <TextField
              id="password"
              type="password"
              name="password"
              margin="normal"
              variant="outlined"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                classes: {
                  root: classes.fieldOutlinedInput,
                  focused: classes.fieldFocused,
                  notchedOutline: classes.fieldNotchedOutline,
                },
              }}
              FormHelperTextProps={{
                classes: {
                  root: classes.errorText,
                },
              }}
              error={Boolean(errors.password && touched.password)}
              helperText={
                errors.password && touched.password
                  ? formatMessage({
                      id: `error.${errors.password}`,
                      defaultMessage: errors.defaultErrorMessage
                        ? errors.defaultErrorMessage
                        : formatMessage({ id: 'mailSignup.passwordHelp' }),
                    })
                  : ''
              }
            />
          </FormControl>
          <Container style={{ width: '100%', margin: 0, padding: '0px 0px 0px 15px' }}>
            <LoaderButton loading={isSubmitting} success={status}>
              <Typography variant="h2">
                {formatMessage({
                  id: 'button.finish',
                })}
              </Typography>
            </LoaderButton>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
