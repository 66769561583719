import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { Form, Formik, FormikHelpers /* , useFormikContext */ } from 'formik';
import * as Yup from 'yup';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { RouteChildrenProps } from 'react-router';
import { Theme, FormControl, TextField } from '@material-ui/core';

import LoaderButton from '../utils/LoaderButton';
import ResendButtonWrapper from './ResendSignUpButtonWrapper';

import { useLocale } from '../../i18n/LocaleContext';
import { AuthError, useUser } from '../../hooks/UserContext';
import { appBarHeight } from '../../App';

const ConfirmSignupSchema = Yup.object().shape({
  confirmationCode: Yup.string().required(),
});

interface ConfirmSignupValues {
  confirmationCode: string;
  defaultErrorMessage?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  confirmTab: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 0,
    },
    margin: 'auto',
    marginTop: appBarHeight + 50,
    width: 480,
    padding: 0,
  },
  backBar: {
    [theme.breakpoints.down('xs')]: {
      background: 'rgba(242, 242, 242, 1)',
    },
    height: 54,
    background: 'rgba(77, 77, 79, 1)',
  },
  backToolBar: {
    height: 54,
    verticalAlign: 'center',
    padding: 0,
  },
  confirmBody: {
    paddingTop: 40,
    padding: 48,
  },
  fields: {
    borderRadius: 46,
    background: '#F2F2F2',
  },
  fieldOutlinedInput: {
    '&$fieldFocused $fieldNotchedOutline': {
      borderWidth: 0,
    },
    borderRadius: 46,
    background: '#F2F2F2',
  },
  fieldFocused: {},
  fieldNotchedOutline: {
    borderWidth: 0,
  },
  errorText: {
    marginLeft: 0,
    marginBottom: 0,
  },
  confirmText: {
    marginTop: 10,
  },
}));

const Confirm: React.FC<RouteChildrenProps> = ({ history }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const { langAndCountryFormated } = useLocale();
  const { confirmSignUp, tempUser, login, checkCrmUserExists } = useUser();
  const [username, setUsername] = useState<string>(tempUser.username);

  useEffect(() => {
    const checkCrm = async () => {
      const {
        // eslint-disable-next-line camelcase
        attributes: { email, name, family_name, locale },
        cognitoUser: { userSub },
      } = tempUser;
      await checkCrmUserExists(email, name, family_name, locale, userSub);
    };
    if (
      tempUser !== null &&
      tempUser.attributes !== null &&
      typeof tempUser.attributes !== 'undefined' &&
      tempUser.attributes.email !== null &&
      typeof tempUser.attributes.email !== 'undefined'
    ) {
      checkCrm();
      const { username: email } = tempUser;
      setUsername(email);
    }
  }, [checkCrmUserExists, tempUser]);

  const handleSubmit = async (
    values: ConfirmSignupValues,
    { setSubmitting, setErrors, setStatus }: FormikHelpers<ConfirmSignupValues>,
  ) => {
    const { username: email, password } = tempUser;
    try {
      await confirmSignUp(email, values.confirmationCode);
      // TODO CHange with contactId of CRM (in back?)
      // if (typeof tempUser.prefered_username !== 'undefined') {
      //   // Update user attributes
      //   await updatePreferredUsernameAttribute(tempUser.cognitoUser, tempUser.preferred_username);
      // }
      await login(email, password).catch(err => {
        if (err.code === 'NotAuthorizedException') {
          history.push(`/${langAndCountryFormated}/login`);
        }
      });
      setStatus(true);
      setSubmitting(false);
    } catch (e) {
      const err = e as AuthError;
      if (err.code === 'UserNotFoundException') {
        // Try with username because of cognito sdk bug -> https://github.com/aws/aws-sdk-net/issues/866
        try {
          await confirmSignUp(email, values.confirmationCode);
          // TODO CHange with contactId of CRM (in back?)
          // if (typeof tempUser.prefered_username !== 'undefined') {
          //   // Update user attributes
          //   await updatePreferredUsernameAttribute(tempUser.cognitoUser, tempUser.preferred_username);
          // }
          await login(email, password).catch(error => {
            if (error.code === 'NotAuthorizedException') {
              history.push(`/${langAndCountryFormated}/login`);
            }
          });
          setStatus(true);
          setSubmitting(false);
        } catch (error) {
          setErrors({ confirmationCode: err.name });
          // eslint-disable-next-line no-param-reassign
          values.defaultErrorMessage = '';
          setSubmitting(false);
        }
      } else {
        // eslint-disable-next-line no-param-reassign
        values.defaultErrorMessage = '';
        setErrors({ confirmationCode: err.name });
        setSubmitting(false);
      }
    }
  };

  return (
    <Formik
      initialValues={
        {
          confirmationCode: '',
          defaultErrorMessage: '',
        } as ConfirmSignupValues
      }
      validationSchema={ConfirmSignupSchema}
      onSubmit={handleSubmit}
      validateOnChange
    >
      {({ values, errors, touched, handleChange, handleBlur, isSubmitting, status }) => (
        <Container component="main" maxWidth={false} className={classes.confirmTab}>
          <Container className={classes.confirmBody}>
            <Typography variant="h1">{formatMessage({ id: 'confirm.title' })}</Typography>
            <div className={classes.confirmText}>
              <Typography variant="h6">
                {formatMessage({ id: 'confirm.text1' })}
                <br />
                {formatMessage({ id: 'confirm.text2' })}
                <br />
                {formatMessage({ id: 'confirm.text3' })}
              </Typography>
            </div>
            <Form>
              <FormControl style={{ marginTop: 32 }} fullWidth>
                <Typography variant="h3">{formatMessage({ id: 'confirm.code' })}</Typography>
                <TextField
                  id="confirmationCode"
                  type="text"
                  name="confirmationCode"
                  margin="normal"
                  variant="outlined"
                  value={values.confirmationCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    classes: {
                      root: classes.fieldOutlinedInput,
                      focused: classes.fieldFocused,
                      notchedOutline: classes.fieldNotchedOutline,
                    },
                  }}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.errorText,
                    },
                  }}
                  error={Boolean(errors.confirmationCode && touched.confirmationCode)}
                  helperText={
                    errors.confirmationCode && touched.confirmationCode
                      ? formatMessage({
                          id: `error.${errors.confirmationCode}`,
                          defaultMessage: errors.defaultErrorMessage
                            ? errors.defaultErrorMessage
                            : formatMessage({ id: 'confirm.required' }),
                        })
                      : ''
                  }
                  fullWidth
                />
              </FormControl>
              <LoaderButton loading={isSubmitting} success={status}>
                {formatMessage({ id: 'confirm.finish' })}
              </LoaderButton>
            </Form>
            <ResendButtonWrapper username={username} />
          </Container>
        </Container>
      )}
    </Formik>
  );
};

export default Confirm;
