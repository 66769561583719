export default {
  send: 'Pošli4',
  email: 'Emailová adresa',
  back: 'späť',
  signWithEmail: 'Zaregistrujte sa pomocou e-mailu',
  tabLogin: 'Prihlásiť sa',
  tabSign: 'Prihlásiť se',
  newPassword: 'Nové heslo',

  confirm: {
    confirm: 'Potvrdiť',
    code: 'Overovací kód',
    title: 'Potvrďte registráciu',
    required: 'Potvrdiť kód je povinné pole',
  },

  newpassword: {
    title: 'Zadajte svoje nové heslo',
  },

  login: {
    password: 'Heslo',
    connect: 'Prihlásiť sa',
    connectUsing: 'Prihláste sa pomocou',
    withEmail: 'alebo e-mailom',
    passwordHelp: 'Min: 8 znakov',
    logout: 'Odhlásiť sa',
    remember: 'Zapamätaj si moje informácie',
    forgot: 'Zabudol som svoje heslo',
  },

  signup: {
    with: 'Zaregistrujte sa pomocou ',
    or: 'alebo',
  },

  reset: {
    resetPassword: 'Obnoviť heslo',
    temporaryPassword: 'Skontrolujte si vo svojej schránke dočasné heslo',
    goToLogin: 'Vráťte sa späť a prihláste sa',
  },

  mailSignup: {
    signUp: 'Prihlásiť Se',
    firstName: 'Krstné meno',
    lastName: 'Priezvisko',
    choosePassword: 'Zvoliť heslo',
    hide: 'Skryť',
    show: 'Šou',
    passwordHelp: 'Min .: 8 znakov, z toho 1 veľké, 1 malé a 1 číselné',
    country: 'Krajina',
    language: 'Jazyk',
    receiveInfo: 'Chcem dostávať informácie Niko',
    textPrivacyPolicy: 'Odoslaním tohto formulára vyjadrujete súhlas s našimi  ',
    privacyPolicy: 'zásadami ochrany osobných údajov',
  },

  language: {
    french: 'francúzština',
    english: 'anglicky',
    swedish: 'švédčina',
    danish: 'dánčina',
    slovak: 'Slovak',
    dutch: 'holandčina',
    german: 'nemčina',
  },

  country: {
    belgium: 'Belgicko',
    denmark: 'Dánsko',
    france: 'Francúzsko',
    sweden: 'Švédsko',
    slovakia: 'slovensko',
    germany: 'nemecko',
    netherland: 'Holandsko',
  },

  error: {
    apicall: 'Na serveri API sa vyskytla chyba!',
    UserNotFoundException: 'Používateľ neexistuje.',
    NotAuthorizedException: 'Nesprávne užívateľské meno alebo heslo.',
    NotAuthorizedExceptionReset: 'Nesprávne používateľské meno.',
    UsernameExistsException: 'Účet s daným e-mailom už existuje.',
    InvalidParameterExceptionPassword: 'Heslo nebolo v súlade s pravidlami: heslo musí mať dĺžku väčšiu alebo rovnú 8.',
    InvalidPasswordExceptionLong: 'Heslo nebolo v súlade s pravidlami: heslo nie je dostatočne dlhé.',
    InvalidPasswordExceptionLower: 'Heslo nebolo v súlade s pravidlami: heslo musí obsahovať malé písmená.',
    InvalidPasswordExceptionUpper: 'Heslo nebolo v súlade s pravidlami: heslo musí obsahovať veľké písmená.',
    InvalidPasswordExceptionNumeric: 'Heslo nebolo v súlade s pravidlami: heslo musí obsahovať číselné znaky.',
    CodeMismatchException: 'Poskytli ste neplatný overovací kód, skúste to znova.',
    email: 'Chýba',
    confirmationCode: 'Chýba',
    AuthError: 'Chybný potvrdzovací kód',
    crmError:
      'Počas protokolovania sa vyskytla neočakávaná chyba. Ak chcete problém vyriešiť, kontaktujte spoločnosť Niko.',
  },
};
