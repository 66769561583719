import React, { useEffect } from 'react';

import { useIntl } from 'react-intl';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { useLocale } from '../../i18n/LocaleContext';
import { appBarHeight } from '../../App';
import CancelButton from '../utils/CancelButton';
import EmailForm from './ResetEmailForm';
import PasswordForm from './ResetPasswordForm';

const useStyles = makeStyles((theme: Theme) => ({
  resetTab: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 0,
    },
    margin: 'auto',
    marginTop: appBarHeight + 50,
    width: 480,
    padding: 0,
  },

  resetBody: {
    paddingTop: 40,
    padding: 48,
  },
  cancelButtonWrapper: {
    position: 'relative',
    left: -7.5,
    paddingLeft: 15,
  },
}));

interface ResetProps {
  email?: string;
}

const Reset: React.FC<ResetProps> = ({ email }) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const { langAndCountryFormated } = useLocale();

  const [showConfirmation, setConfirmation] = React.useState<boolean>(false);
  const [userEmail, setEmail] = React.useState<string>('');

  useEffect(() => {
    if (email) {
      setEmail(email);
      setConfirmation(true);
    }
  }, []);

  return (
    <Container component="main" maxWidth={false} className={classes.resetTab}>
      <Container className={classes.resetBody}>
        <Typography variant="h1">{formatMessage({ id: 'reset.resetPassword' })}</Typography>
        {!showConfirmation ? (
          <EmailForm setConfirmation={setConfirmation} setEmail={setEmail} />
        ) : (
          <PasswordForm email={userEmail} />
        )}

        <div className={classes.cancelButtonWrapper}>
          <CancelButton
            label={formatMessage({ id: 'back' })}
            onClick={() => {
              history.push(`/${langAndCountryFormated}/login`);
            }}
          />
        </div>
      </Container>
    </Container>
  );
};

export default Reset;
