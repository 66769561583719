import React, { Dispatch, SetStateAction } from 'react';

import { useRouteMatch, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, CssBaseline, Typography, Container, AppBar } from '@material-ui/core';
import { useLocale } from './i18n/LocaleContext';
import { useUser } from './hooks/UserContext';
import niko from './img/niko.svg';

import { AuthenticatedRoutes, UnauthenticatedRoutes } from './components/utils/Routes';

export interface TabProps {
  initialTab: number;
  updateTab: Dispatch<SetStateAction<number>>;
}

export const tabsMatching = {
  login: 0,
  signUp: 1,
};

export const tabsMatchingReverse: string[] = ['login', 'signUp'];

export const appBarHeight = 64;

const useStyles = makeStyles((theme: Theme) => ({
  appbar: {
    [theme.breakpoints.down('xs')]: {
      position: 'static',
    },
    position: 'absolute',

    background: theme.palette.primary.main,
    height: appBarHeight,
  },
  logo: {
    flexGrow: 1,
    marginLeft: 32,
    display: 'flex',
    alignItems: 'center',
  },

  tabWrapper: {
    padding: 20,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainFrame: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 0,
    },
    margin: 'auto',
    marginTop: appBarHeight + 50,
    background: 'white',
    width: 480,
    padding: 0,
  },
}));

const App = () => {
  const { user, changePasswordChallenge } = useUser();
  const classes = useStyles();
  const { updateLanguage, updateCountry, langAndCountryFormated } = useLocale();
  const history = useHistory();

  const match = useRouteMatch({
    path: '/:lang/:path',
  });

  let [l, c] = ['', ''];
  if (match !== null) {
    if ('lang' in match.params) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [l, c] = (match as any).params.lang.split('-');
      updateCountry(c);
      updateLanguage(l);
    }
  }

  const routing = () => (user ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />);

  return (
    <Container className={classes.mainFrame}>
      <CssBaseline />
      <AppBar className={classes.appbar}>
        <Typography className={classes.logo}>
          <img
            src={niko}
            style={{ cursor: changePasswordChallenge || !user ? 'pointer' : 'auto' }}
            onClick={() => {
              if (changePasswordChallenge || !user) history.push(`/${langAndCountryFormated}/login`);
            }}
            alt="Niko"
          />
        </Typography>
      </AppBar>
      {routing()}
    </Container>
  );
};
export default App;
