// Valid URL from sitecore
const URLS_PER_ENV = {
  'dev.id.niko.eu': [
    'https://stag-cm-dev-niko-demo.nkds-vnext.dlwnet.com',
    'https://stag-cd-dev-niko-demo.nkds-vnext.dlwnet.com',
    'https://cm-dev-niko-demo.nkds-vnext.dlwnet.com',
    'https://cd-dev-niko-demo.nkds-vnext.dlwnet.com',
    'https://stag-cm-dev-niko.nkds-vnext.dlwnet.com',
    'https://stag-cd-dev-niko.nkds-vnext.dlwnet.com',
    'https://cm-dev-niko.nkds-vnext.dlwnet.com',
    'https://cd-dev-niko.nkds-vnext.dlwnet.com',
    // Detector tool app
    'https://detectortoolservicedev.azurewebsites.net',
    'https://dev.detectortoolportal.niko.eu',
    'https://nikodetectortool.niko.eu',
    'https://nikodetectortool.eu',
    'http://172.15.0.155:8000',
    'http://192.168.0.169:8000',
    // Staging - CM
    'https://stag-cm-dev.niko.eu',
    'https://stag-cm-dev-demo.niko.eu',
    // Staging - CD
    'https://stag-cd-dev.niko.eu',
    'https://stag-cd-dev-demo.niko.eu',
    // Prod CM
    'https://cm-dev.niko.eu',
    'https://cm-dev-demo.niko.eu',
    // Prod - CD
    'https://cd-dev.niko.eu',
    'https://cd-dev-demo.niko.eu',
    // Dev
    'http://localhost',
    'https://d-gatekeeper.cloud.niko.eu',
    // FA Unamed project
    'http://localhost:8080',
    'https://dev2.fa.niko.eu',
  ],
  'qa.id.niko.eu': [
    'https://cd-qa-niko.nkds-vnext.dlwnet.com',
    'https://cm-qa-niko.nkds-vnext.dlwnet.com',
    'https://stag-cm-qa-niko.nkds-vnext.dlwnet.com',
    'https://stag-cd-qa-niko.nkds-vnext.dlwnet.com',
    'https://stag-cm-qa-niko-demo.nkds-vnext.dlwnet.com',
    'https://stag-cd-qa-niko-demo.nkds-vnext.dlwnet.com',
    'https://cm-qa-niko-demo.nkds-vnext.dlwnet.com',
    'https://cd-qa-niko-demo.nkds-vnext.dlwnet.com',
    // Detector tool app
    'https://detectortoolserviceqa.azurewebsites.net',
    'https://qa.detectortoolportal.niko.eu',
    'https://nikodetectortool.niko.eu',
    'https://nikodetectortool.eu',
    'http://172.15.0.155:8000',
    'http://192.168.0.169:8000',
    // Staging - CM
    'https://stag-cm-qa.niko.eu',
    'https://stag-cm-qa-demo.niko.eu',
    // Staging - CD
    'https://stag-cd-qa.niko.eu',
    'https://stag-cd-qa-demo.niko.eu',
    // Prod - CM
    'https://cm-qa.niko.eu',
    'https://cm-qa-demo.niko.eu',
    // Prod - CD
    'https://cd-qa.niko.eu',
    'https://cd-qa-demo.niko.eu',
    // Dev
    'http://localhost',
    'https://s-gatekeeper.cloud.niko.eu',
    'https://demo-gatekeeper.cloud.niko.eu',
    // FA Unamed project
    'https://test.fa.niko.eu',
  ],
  'id.niko.eu': [
    'https://cd-prd-niko.nkds-vnext.dlwnet.com',
    'https://cm-prd-niko.nkds-vnext.dlwnet.com',
    'https://stag-cm-prd-niko.nkds-vnext.dlwnet.com',
    'https://stag-cd-prd-niko.nkds-vnext.dlwnet.com',
    // Detector tool app
    'https://detectortoolserviceprod.azurewebsites.net',
    'https://detectortoolportal.niko.eu',
    'https://nikodetectortool.niko.eu',
    // Staging - CM
    'https://stag-cm-prd.niko,eu',
    // Staging - CD
    'https://stag-cd-prd.niko.eu',
    // Prod - CM
    'https://cm-prd.niko.eu',
    // Prod - CD
    'https://niko.eu',
    'https://www.niko.eu',
    'https://gatekeeper.cloud.niko.eu',
    // FA Unamed project
    'https://fa.niko.eu',
    // gatekeeper project
    'https://gatekeeper.cloud.niko.eu',
    // FORMS project
    'https://forms.niko.eu',
  ],
  debug: [
    'http://localhost:50800',
    'http://localhost:3000',
    'http://localhost:8000',
    'https://dev.detectortoolportal.niko.eu',
  ],
};

export const GET_EXCEPTION_LIST: Array<string> = [];

const { host } = window.location;

// @ts-ignore
export default URLS_PER_ENV[host] ? URLS_PER_ENV[host] : URLS_PER_ENV.debug;
