export default {
  send: 'Sende',
  email: 'Email adresse',
  back: 'Tilbage',
  signWithEmail: 'Tilmeld dig med e-mail',
  tabLogin: 'Log på',
  tabSign: 'Tilmelde',
  newPassword: 'Nyt kodeord',

  confirm: {
    confirm: 'Bekræfte',
    code: 'Bekræftelseskode',
    title: 'Bekræft tilmelding',
    required: 'Bekræft kode er et obligatorisk felt',
  },

  newpassword: {
    title: 'Indtast venligst din nye adgangskode',
  },

  login: {
    password: 'Adgangskode',
    connect: 'Log på',
    connectUsing: 'Log ind med',
    withEmail: 'eller med e-mail',
    passwordHelp: 'Min: 8 tegn',
    logout: 'Log ud',
    remember: 'Husk mine oplysninger',
    forgot: 'Jeg har glemt mit kodeord',
  },

  signup: {
    with: 'Tilmeld dig med',
    or: 'eller',
  },

  reset: {
    resetPassword: 'Nulstille kodeord',
    temporaryPassword: 'Tjek din postkasse for en midlertidig adgangskode',
    goToLogin: 'Gå tilbage til login',
  },

  mailSignup: {
    signUp: 'Tilmelde',
    firstName: 'Fornavn',
    lastName: 'Efternavn',
    choosePassword: 'Vælg adgangskode',
    hide: 'Skjule',
    show: 'At vise',
    passwordHelp: 'Min: 8 tegn inklusive 1 store bogstaver, 1 små og 1 tal',
    country: 'Land',
    language: 'Sprog',
    receiveInfo: 'Jeg vil gerne modtage Niko information',
    textPrivacyPolicy: 'Ved at indsende denne formular accepterer du vores ',
    privacyPolicy: 'fortrolighedspolitik',
  },

  language: {
    french: 'fransk',
    english: 'engelsk',
    swedish: 'svensk',
    danish: 'dansk',
    slovak: 'slovakisk',
    dutch: 'hollandsk',
    german: 'tysk',
  },

  country: {
    belgium: 'Belgien',
    denmark: 'Danmark',
    france: 'Frankrig',
    sweden: 'Sverige',
    slovakia: 'Slovakiet',
    germany: 'Tyskland',
    netherland: 'Holland',
  },

  error: {
    apicall: 'Der gik noget galt på api-serveren!',
    UserNotFoundException: 'Bruger eksisterer ikke.',
    NotAuthorizedException: 'Forkert brugernavn eller kodeord.',
    NotAuthorizedExceptionReset: 'Forkert brugernavn.',
    UsernameExistsException: 'Der findes allerede en konto med den givne e-mail.',
    InvalidParameterExceptionPassword:
      // eslint-disable-next-line max-len
      'Adgangskoden var ikke i overensstemmelse med politikken: Adgangskoden skal have en længde større end eller lig med 8.',
    InvalidPasswordExceptionLong:
      'Adgangskoden var ikke i overensstemmelse med politikken: Adgangskoden var ikke længe nok.',
    InvalidPasswordExceptionLower:
      'Adgangskoden var ikke i overensstemmelse med politikken: Adgangskoden skal have små bogstaver.',
    InvalidPasswordExceptionUpper:
      'Adgangskoden var ikke i overensstemmelse med politikken: Adgangskoden skal have store bogstaver.',
    InvalidPasswordExceptionNumeric:
      'Adgangskoden var ikke i overensstemmelse med politikken: Adgangskoden skal have numeriske tegn.',
    CodeMismatchException: 'Ugyldig verifikationskode angivet. Prøv igen.',
    email: 'Mangler',
    confirmationCode: 'Mangler',
    AuthError: 'Forkert bekræftelse torsk',
    crmError: 'Der opstod en uventet fejl under logning. Kontakt Niko for at løse problemet.',
  },
};
