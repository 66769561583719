export default {
  send: 'Skicka',
  email: 'E-postadress',
  back: 'Tillbaka',
  signWithEmail: 'Registrera dig med e-post',
  tabLogin: 'Logga in',
  tabSign: 'Bli Medlem',
  newPassword: 'Nytt lösenord',

  confirm: {
    confirm: 'Bekräfta',
    code: 'Bekräftelsekod',
    title: 'Bekräfta registreringen',
    required: 'Bekräfta att koden är ett obligatoriskt fält',
  },

  newpassword: {
    title: 'Ange ditt nya lösenord',
  },

  login: {
    password: 'Lösenord',
    connect: 'Logga in',
    connectUsing: 'Logga in med',
    withEmail: 'eller med e-post',
    passwordHelp: 'Min: 8 tecken',
    logout: 'Logga ut',
    remember: 'Kom ihåg min information',
    forgot: 'Jag har glömt mitt lösenord',
  },

  signup: {
    with: 'Registrera med ',
    or: 'eller',
  },

  reset: {
    resetPassword: 'Återställ lösenord',
    temporaryPassword: 'Kontrollera om det finns ett tillfälligt lösenord i din brevlåda',
    goToLogin: 'Gå tillbaka till inloggningen',
  },

  mailSignup: {
    signUp: 'Bli Medlem',
    firstName: 'Förnamn',
    lastName: 'Efternamn',
    choosePassword: 'Välj lösenord',
    hide: 'Dölj',
    show: 'Visa',
    passwordHelp: 'Min: 8 tecken inklusive 1 versaler, 1 gemener och 1 nummer',
    country: 'Land',
    language: 'Språk',
    receiveInfo: 'Jag vill få information om Niko',
    textPrivacyPolicy: 'Genom att skicka detta formulär godkänner du vår ',
    privacyPolicy: 'integritetspolicy',
  },

  language: {
    french: 'franska',
    english: 'engelska',
    swedish: 'svenska',
    danish: 'danska',
    slovak: 'slovakiska',
    dutch: 'holländska',
    german: 'tyska',
  },

  country: {
    belgium: 'Belgien',
    denmark: 'Danmark',
    france: 'Frankrike',
    sweden: 'Sverige',
    slovakia: 'Slovakien',
    germany: 'Tyskland',
    netherland: 'Nederländerna',
  },

  error: {
    apicall: 'Något gick fel på api-servern!',
    UserNotFoundException: 'Användare finns inte.',
    NotAuthorizedException: 'Felaktigt användarnamn eller lösenord.',
    NotAuthorizedExceptionReset: 'Felaktigt användarnamn.',
    UsernameExistsException: 'Ett konto med det angivna e-postmeddelandet finns redan.',
    InvalidParameterExceptionPassword:
      'Lösenordet överensstämde inte med policyn: Lösenordet måste ha en längd som är större än eller lika med 8.',
    InvalidPasswordExceptionLong: 'Lösenordet överensstämde inte med policyn: Lösenordet är inte tillräckligt länge.',
    InvalidPasswordExceptionLower: 'Lösenordet överensstämde inte med policyn: Lösenordet måste ha små bokstäver.',
    InvalidPasswordExceptionUpper: 'Lösenordet överensstämde inte med policyn: Lösenordet måste ha versaler.',
    InvalidPasswordExceptionNumeric: 'Lösenordet överensstämde inte med policyn: Lösenordet måste ha numeriska tecken.',
    CodeMismatchException: 'Ogiltig verifieringskod tillhandahållen, försök igen.',
    email: 'Saknas',
    confirmationCode: 'Saknas',
    AuthError: 'Fel bekräftelsekod',
    crmError: 'Ett oväntat fel uppstod under loggningen. Kontakta Niko för att lösa problemet.',
  },
};
