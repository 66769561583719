import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useUser } from '../../hooks/UserContext';
import { useLocale } from '../../i18n/LocaleContext';

const useStyles = makeStyles(() => ({
  tabWrapper: {
    padding: 20,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const ExitCard: React.FC = () => {
  const { formatMessage } = useIntl();
  const { logout } = useUser();
  const { langAndCountryFormated } = useLocale();
  const history = useHistory();
  const classes = useStyles();

  return (
    <Container className={classes.tabWrapper} maxWidth={false}>
      <Typography variant="h1">{formatMessage({ id: 'logout', defaultMessage: 'You are logged in' })}</Typography>
      <Button
        style={{ marginTop: 30 }}
        onClick={async () => {
          await logout();
          history.push(`/${langAndCountryFormated}/login`);
          return null;
        }}
      >
        Log out
      </Button>
    </Container>
  );
};

export default ExitCard;
