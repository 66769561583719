/* eslint-disable max-len */
export default {
  send: 'Stuur',
  email: 'E-mail',
  back: 'Terug',
  signWithEmail: 'Aanmelden met e-mail',
  tabLogin: 'Aanmelden',
  tabSign: 'Inschrijven',
  newPassword: 'Nieuw wachtwoord',

  confirm: {
    confirm: 'Bevestigen',
    code: 'Bevestigingscode',
    title: 'Bevestig inschrijving',
    required: 'Bevestig de code is een verplicht veld',
  },

  newpassword: {
    title: 'Voer uw nieuwe wachtwoord in',
  },

  login: {
    password: 'Wachtwoord',
    connect: 'Aanmelden',
    connectUsing: 'Log in met behulp van',
    withEmail: 'of met e-mail',
    passwordHelp: 'Min: 8 tekens',
    logout: 'Log uit',
    remember: 'Onthoud mijn informatie',
    forgot: 'Wachtwoord vergeten',
  },

  signup: {
    with: 'Schrijf u in met',
    or: 'or',
  },

  reset: {
    resetPassword: 'Wachtwoord resetten',
    temporaryPassword: 'Controleer uw mailbox voor een tijdelijk wachtwoord',
    goToLogin: 'Ga terug naar aanmelden',
  },

  mailSignup: {
    signUp: 'Inschrijven',
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    choosePassword: 'Kies een wachtwoord',
    hide: 'Verberg',
    show: 'Toon',
    passwordHelp: 'Min: 8 tekens, inclusief 1 hoofdletter, 1 kleine letter en 1 cijfer',
    country: 'Land',
    language: 'Taal',
    receiveInfo: 'Ik wil Niko informatie ontvangen',
    textPrivacyPolicy: 'Door het indienen van dit formulier gaat u akkoord met onze ',
    privacyPolicy: 'privacy policy',
  },

  language: {
    french: 'Frans',
    english: 'Engels',
    swedish: 'Zweeds',
    danish: 'Deens',
    slovak: 'Slowaaks',
    dutch: 'Nederlands',
    german: 'Duits',
  },

  country: {
    belgium: 'België',
    denmark: 'Denemarken',
    france: 'Frankrijk',
    sweden: 'Zweden',
    slovakia: 'Slowakije',
    germany: 'Duitsland',
    netherland: 'Nederland',
  },

  error: {
    apicall: 'Er is iets misgegaan op de API server!',
    UserNotFoundException: 'De gebruiker bestaat niet.',
    NotAuthorizedException: 'Onjuiste gebruikersnaam of wachtwoord.',
    NotAuthorizedExceptionReset: 'Onjuiste gebruikersnaam.',
    UsernameExistsException: 'Er bestaat al een account bij de betreffende e-mail.',
    InvalidParameterExceptionPassword:
      'Wachtwoord is niet in overeenstemming met het beleid: Wachtwoord moet een lengte hebben die groter is dan of gelijk is aan 8.',
    InvalidPasswordExceptionLong: 'Wachtwoord is niet in overeenstemming met het beleid: Wachtwoord niet lang genoeg.',
    InvalidPasswordExceptionLower:
      'Wachtwoord is niet in overeenstemming met het beleid: Wachtwoord moet kleine letters hebben.',
    InvalidPasswordExceptionUpper:
      'Wachtwoord is niet in overeenstemming met het beleid: Wachtwoord moet hoofdletters bevatten.',
    InvalidPasswordExceptionNumeric:
      'Wachtwoord is niet in overeenstemming met het beleid: Wachtwoord moet numerieke tekens hebben.',
    CodeMismatchException: 'Ongeldige verificatiecode verstrekt, probeer het opnieuw.',
    email: 'Ontbrekend',
    confirmationCode: 'Montbrekend',
    AuthError: 'Verkeerde bevestigingscode',
    crmError:
      'Er is een onverwachte fout opgetreden tijdens het loggen, neem contact op met Niko om het probleem op te lossen.',
  },
};
