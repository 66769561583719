/* eslint-disable max-len */
export default {
  send: 'Envoyer',
  email: 'Adresse email',
  back: 'Retour',
  signWithEmail: "S'enregistrer par email",
  tabLogin: 'Se connecter',
  tabSign: 'Inscription',
  newPassword: 'Nouveau mot de passe',
  newEmail: 'Nouvelle adresse email',
  confirmNewEmail: 'Confirmer nouvelle adresse email',

  confirm: {
    confirm: 'Confirmer',
    code: 'Code de confirmation',
    title: 'Vérifier votre boîte mail pour le code de confirmation',
    required: 'Le code de confirmation est un champ requis',
    resendCode: 'Nouveau Code',
    resendCodePrompt: 'Un nouveau code de confirmation a été envoyé, veuillez consulter votre boite de réception.',
  },

  newpassword: {
    title: 'Veuillez entrer votre nouveau mot de passe',
  },

  login: {
    password: 'Mot de passe',
    connect: 'Se connecter',
    connectUsing: 'Se connecter avec',
    withEmail: 'ou par email',
    passwordHelp: 'Min: 8 charactères',
    logout: 'Se déconnecter',
    remember: 'Se souvenir de mes informations',
    forgot: "J'ai oublié",
  },

  signup: {
    with: "S'enregister avec ",
    or: 'ou',
  },

  reset: {
    resetPassword: 'Réinitialiser le mot de passe',
    temporaryPassword: 'Vérifier votre boîte mail pour un mot de passe temporaire',
    goToLogin: "Revenir à l'écran de connexion",
  },

  mailSignup: {
    signUp: "S'inscrire",
    firstName: 'Prénom',
    lastName: 'Nom',
    choosePassword: 'Choisissez votre mot de passe',
    hide: 'Cacher',
    show: 'Montrer',
    passwordHelp: 'min: 8 charactères dont 1 majuscule, 1 minuscule et 1 chiffre',
    country: 'Pays',
    language: 'Langue',
    receiveInfo: 'Je veux recevoir des informations de Niko',
    privacyPolicy: 'politique de confidentialité',
    textPrivacyPolicy: 'En soumettant ce formulaire, vous acceptez notre ',
  },

  language: {
    french: 'Français',
    english: 'Anglais',
    swedish: 'Suèdois',
    danish: 'Danois',
    slovak: 'Slovaque',
    dutch: 'Néerlandais',
    german: 'Allemand',
  },

  country: {
    belgium: 'Belgique',
    denmark: 'Danemark',
    france: 'France',
    sweden: 'Suède',
    slovakia: 'Slovaquie',
    germany: 'Allemagne',
    netherland: 'Pays-bas',
  },

  error: {
    apicall: "Quelque chose s'est mal passé sur le serveur api !",
    UserNotFoundException: "L'utilisateur n'existe pas.",
    NotAuthorizedException: "Nom d'utilisateur ou mot de passe incorrect.",
    NotAuthorizedExceptionReset: "Nom d'utilisateur incorrect.",
    UsernameExistsException: 'Un compte avec cette adresse email existe déjà.',
    InvalidParameterExceptionPassword:
      'Le mot de passe ne respecte pas les exigences de complexité: le mot de passe doit contenir au minimum 8 caractères.',
    InvalidPasswordExceptionLong:
      'Le mot de passe ne respecte pas les exigences de complexité: le mot de passe est trop court.',
    InvalidPasswordExceptionLower:
      'Le mot de passe ne respecte pas les exigences de complexité: le mot de passe doit contenir au minimum une minuscule.',
    InvalidPasswordExceptionUpper:
      'Le mot de passe ne respecte pas les exigences de complexité: le mot de passe doit contenir au minimum une majuscule.',
    InvalidPasswordExceptionNumeric:
      'Le mot de passe ne respecte pas les exigences de complexité: le mot de passe doit contenir au minimum une chiffre.',
    CodeMismatchException: 'Mauvaise code de validation, veuillez réésayer.',
    email: 'Manquant',
    confirmationCode: 'Manquant',
    AuthError: 'Code de confirmation erroné',
    crmError:
      "Une erreur inattendue s'est produite lors de la connexion, veuillez contacter Niko pour résoudre le problème.",
    newEmailMismatch: 'Les adressses email doivent correspondre.',
    newEmailBasic: 'Champ invalide',
    newEmailWrongFormat: 'Doit être une adresse email valide.',
    newEmailUserNotFound: 'Vous devez être connecté·e pour changer votre email.',
  },
};
